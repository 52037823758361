import React, { useContext, useEffect, useState } from 'react';
import { LocationContext } from '../../state/LocationContext';
import { client } from '../../utils';
import { Location } from '@prp/shared';

type ComponentProps = {
  children: React.ReactNode;
};

export const LocationFromBrowser: React.FC<ComponentProps> = ({ children }) => {
  const [browserCoordinates, setBrowserCoordinates] = useState<
    Location | null | undefined
  >(undefined);

  const { location, setLocation, promptedForLocation, setPromptedForLocation } =
    useContext(LocationContext);

  const locationInput = browserCoordinates ? browserCoordinates : undefined;
  const { data: locationOutput, refetch: refetchLocation } =
    client.location.getLocation.useQuery(locationInput, {
      enabled: false,
    });

  useEffect(() => {
    if (browserCoordinates !== undefined) {
      refetchLocation()
        .then(({ data }) => {
          console.log('refetch complete');
        })
        .catch((err) => {
          console.log('refetch error', err);
        });
    }
  }, [browserCoordinates]);

  useEffect(() => {
    if (locationOutput) {
      console.log('setting location to', locationOutput);
      if (!browserCoordinates) {
        setLocation({
          ...locationOutput,
          name: 'Not Set',
          postalCode: 'Not Set',
        });
      } else {
        setLocation(locationOutput);
      }
    }
  }, [locationOutput]);

  useEffect(() => {
    if (location === null && !promptedForLocation) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            setBrowserCoordinates(pos);
          },
          () => {
            console.log('Unable to retrieve location');
            setBrowserCoordinates(null);
          },
        );
      } else {
        setBrowserCoordinates(null);
      }
      setPromptedForLocation(true);
    }
  }, []);

  if (!location && browserCoordinates === undefined) {
    // return <div>Enter Location</div>; //TODO add blank state
    return null;
  }
  return <div>{children}</div>;
};
