import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import ScrollWatcherInstance from './ScrollWatcher';
import config from '../../config';

/*
 * Registers a history listener on mount which
 * sends page transition data to google analytics
 */
export const HistoryWatcher = () => {
  useEffect(() => {
    if (config.appEnv === 'prod') {
      ReactGA.initialize('G-ZWNRQ5G0HT');
      //record initial page load
      ReactGA.send({
        hitType: 'pageview',
        page_path: window.location.pathname + window.location.search,
      });
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (config.appEnv === 'prod') {
      // Record page transition
      ReactGA.send({
        hitType: 'pageview',
        page_path: location.pathname + location.search,
      });
    }
    ScrollWatcherInstance.updateLocation(location.pathname);

    // No need for a cleanup function here, as `useEffect` will re-run
    // only when `location` changes.
  }, [location]); // Dependency on `location`

  return null;
};
