import React from 'react';
import { Footer } from '../layout/Footer';
import { getTitle } from '../../utils';
import PageLabel from '../utils/PageLabel';

const PrivacyPolicy = () => {
  document.title = getTitle('Privacy Policy');

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-20 pb-2 -mx-4">
          <PageLabel title={'Privacy Policy'} />
        </div>
        <div className="container mx-auto px-4 py-8 max-w-prose text-pretty text-left">
          <h1 className="text-3xl font-bold mb-4">
            Privacy Policy for Pet Rescue Plus
          </h1>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
            <p>
              Pet Rescue Plus is committed to protecting your privacy. This
              privacy policy outlines how we handle data processed by our
              application. Please note that our app accesses publicly available
              information from multiple sources, including Google Place
              information, user reviews, ratings, and Facebook overall ratings
              and rating counts. Additionally, we register anonymized user views
              and clicks to help rescue organizations gain insights into how
              they can increase the reach of their message. Pet information is
              sourced from a collection of data feeds, including the PetFinder
              and RescueGroups APIs, as well as the rescue organization's web
              pages.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">2. Data We Collect</h2>
            <p>
              Our app retrieves data from public Facebook pages, Google Places,
              and other public sources, including user reviews, ratings, and
              overall rating counts. We also pull information about adoptable
              pets from the PetFinder API, RescueGroups API, and rescue
              organization web pages. Users are asked to provide their location
              to customize their experience by showing locally adoptable pets.
              We do not access, store, or process any personal information
              beyond location for this purpose.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">
              3. How We Use the Data
            </h2>
            <p>
              The data we retrieve is used solely for the purpose of promoting
              local pet adoption events, providing visibility for adoptable
              pets, and offering rescue organizations insights on improving
              their outreach efforts. All user interactions, such as views and
              clicks, are anonymized and collected to help inform these
              organizations on how best to increase their reach. User-provided
              location data is only used to show locally available adoptable
              pets. Additionally, we use tools such as Google Analytics to
              measure and analyze the overall user experience so that we can
              continue to improve the site over time.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">
              4. Third-Party Services
            </h2>
            <p>
              We use Amazon Web Services for hosting our website and app, but no
              personal data is shared or processed by third-party services. The
              data we retrieve from external sources, such as Google Place
              information, Facebook ratings, and APIs from PetFinder and
              RescueGroups, is publicly available and used strictly for
              informational purposes.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">5. Contact Us</h2>
            <p>
              If you have any questions or concerns about our privacy policy,
              please contact us at{' '}
              <a
                href="mailto:support@petrescueplus.com"
                className="text-blue-600 underline hover:text-blue-800"
              >
                support@petrescueplus.com
              </a>
              .
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
