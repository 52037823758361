import React from 'react';

type ComponentProps<T> = {
  name: string;
  options: [T, string, boolean][];
  updateStatus: (option: T, selectionStatus: boolean) => void;
};

const FilterGroup = <T extends string>({
  name,
  options,
  updateStatus,
}: ComponentProps<T>) => {
  return (
    <div className="filter-group">
      <div className="filter-group-header">{name}</div>
      <div className="filter-options">
        {options.map(([option, label, status]) => (
          <div className="mb-1" key={option}>
            <button
              onClick={() => updateStatus(option, !status)}
              className={`text-xl toggle-button ${status ? 'on' : 'off'}`}
            >
              {label}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterGroup;
