import React, { Component, ErrorInfo, ReactNode } from 'react';
import { ERROR_DETAILS_KEY, ErrorDetails } from '../../utils/index';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Caught an error:', error, errorInfo);

    const errorDetails: ErrorDetails = {
      error: {
        message: error.message,
        stack: error.stack,
      },
      errorInfo: errorInfo,
    };

    sessionStorage.setItem(ERROR_DETAILS_KEY, JSON.stringify(errorDetails));

    // Use window.location for a full page navigation
    window.location.href = '/error';
  }

  render() {
    // We can keep this as a fallback, though the componentDidCatch
    // will usually handle the navigation first
    if (this.state.hasError) {
      return (
        <div className="p-4">
          <h1>Something went wrong</h1>
          <button
            onClick={() => (window.location.href = '/error')}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Go to Error Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
