import { useEffect } from 'react';
import { NavCallback, useNavigationContext } from '../state/NavigationContext';

const useNavigationCallback = (callback: NavCallback) => {
  const { registerCallback } = useNavigationContext();

  useEffect(() => {
    const unregister = registerCallback(callback);
    return () => {
      unregister();
    };
  }, [callback, registerCallback]);
};

export default useNavigationCallback;
