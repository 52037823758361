import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { FaXmark } from 'react-icons/fa6';
import { GiHamburgerMenu } from 'react-icons/gi';

const HamburgerMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event: Event) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex items-center justify-center">
        <button onClick={toggleMenu} className="focus:outline-none">
          {isOpen ? (
            <FaXmark className="h-6 w-6" />
          ) : (
            <GiHamburgerMenu className="h-6 w-6" />
          )}
        </button>
      </div>
      {isOpen && (
        <div
          ref={menuRef}
          className="absolute flex flex-col space-y-3 items-stretch mt-2 text-xl shadow-lg bg-secondary rounded-md text-gray-800"
        >
          <Link to="/" className="hover:bg-gray-100" onClick={closeMenu}>
            Home
          </Link>
          <Link to="/cats" className="hover:bg-gray-100" onClick={closeMenu}>
            Cats
          </Link>
          <Link to="/dogs" className="hover:bg-gray-100" onClick={closeMenu}>
            Dogs
          </Link>
          <Link
            to="/organizations"
            className="hover:bg-gray-100"
            onClick={closeMenu}
          >
            Organizations
          </Link>
          <Link
            to="/resources"
            className="hover:bg-gray-100"
            onClick={closeMenu}
          >
            Resources
          </Link>
          <Link to="/about" className="hover:bg-gray-100" onClick={closeMenu}>
            About
          </Link>
          <Link to="/contact" className="hover:bg-gray-100" onClick={closeMenu}>
            Contact
          </Link>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
