import { z } from 'zod';

const petType = z.enum(['dog', 'cat']);
export const PetSchema = z.object({ petType });
export type PetType = z.infer<typeof petType>;

export function isPetType(value: unknown): value is PetType {
  return petType.safeParse(value).success;
}

const MediaActions = z.enum(['view', 'click']);
export type MediaActionType = z.infer<typeof MediaActions>;
const PhotoInteractionSchema = z.object({
  item: z.literal('photo'),
  petId: z.string(),
  url: z.string(),
  action: MediaActions,
});

const ProfileInteractionSchema = z.object({
  item: z.literal('profile'),
  petId: z.string(),
  action: z.literal('view'),
  // No url property needed for profile view
});

export const PetInteractionSchema = z.discriminatedUnion('item', [
  PhotoInteractionSchema,
  ProfileInteractionSchema,
]);
export type PetInteractionType = z.infer<typeof PetInteractionSchema>;

export interface PersonProfile {
  id: string;
  name: string;
  bio: string;
  gender: 'Male' | 'Female';
  imageUrl: string;
  dateAdded: Date;
  lastUpdatedDate: Date;
  lastActiveDate: Date;
  geoHash: string;
  orgId: string[];
  petIds: string[];
}

export interface Pet {
  id: string;
  name?: string;
  species: PetType;
  source: OrgSource;
  age?: AgeType;
  sex?: SexType;
  size?: SizeType;
  breeds: BreedType;
  isCourtesyListing?: boolean;
  coat?: 'short' | 'long' | 'hypoallergenic';
  attributes: {
    fixed?: boolean;
    houseTrained?: boolean;
    declawed?: boolean;
    specialNeeds?: boolean;
    vaccinationsCurrent?: boolean;
  };
  environment: Environment;
  activityLevel?: 'low' | 'moderate' | 'high';
  tags?: string[];
  adoptionStatus: AdoptionStatusType;
  dateAdded: string;
  lastUpdatedDate: string;
  lastMediaDate: string | null;
  orgId: string;
  caregiverId?: string;
}

export interface Environment {
  children?: boolean;
  dogs?: boolean;
  cats?: boolean;
}

export interface BreedType {
  primary?: string;
  secondary?: string;
}

interface BaseMedia {
  id: string;
  date: string;
  url: string;
  height?: number;
  width?: number;
  altText?: string;
}

export interface PhotoMedia extends BaseMedia {
  mediaType: 'photo';
}

export interface VideoMedia extends BaseMedia {
  mediaType: 'video';
  duration: number;
}

export type MediaType = PhotoMedia | VideoMedia;

export type PhotoCard = {
  petId: string;
  name?: string;
  species: PetType;
  age?: AgeType;
  sex?: SexType;
  size?: SizeType;
  isCourtesyListing?: boolean;
  isSpecialNeeds?: boolean;
  breeds: BreedType;
  environment: Environment;
  lastMediaDate: string;
  orgId: string;
  orgName: string;
};

export type PhotoCardMedia = PhotoCard & PhotoMedia;

export type AdoptedPetInfo = {
  petId: string;
  petName: string;
  species: PetType;
  photo: string;
  orgId: string;
  orgName: string;
};

export interface Post {
  id: string;
  authorId: string;
  title: string;
  content: string;
  pets: Pet[];
  media: MediaType[];
  orgId: string | null;
  tags: string[];
  dateAdded: string;
  lastUpdatedDate: string;
}
// comments: number;
// likes: number;

export interface Comment {
  id: string;
  authorId: string;
  content: string;
  createdAt: string;
  updatedAt?: string;
  mentions: string[];
  likes: number;
}

export interface Like {
  id: string;
  authorId: string;
  createdAt: string;
}

export interface PetProfile extends Pet {
  bio?: string;
  availableDate?: string;
  adoptionFee?: number;
  microchipped?: boolean;
  externalUrl?: string;
  photo: PhotoMedia;
  extPhotos: PhotoMedia[];
}

export type MinimumPetProfile = Partial<PetProfile> &
  Pick<PetProfile, 'species' | 'adoptionStatus' | 'orgId'>;

export type AddressPostcode = {
  address: {
    postcode: string;
  };
};

export type MinOrg = Partial<Organization> &
  AddressPostcode &
  Pick<Organization, 'name' | 'email' | 'source'>;

export type OrgSource = 'RescueGroups' | 'PetFinder' | 'PetRescuePlus';

export interface OrganizationSummary {
  id: string;
  name: string;
  blurb?: string | null;
  cats: boolean;
  dogs: boolean;
  address: Address;
  location: {
    latitude: number;
    longitude: number;
  };
}

export interface Organization {
  id: string;
  name: string;
  source: OrgSource;
  email: string;
  phone?: string | null;
  address: Address;
  hours?: Hours;
  website?: string | null;

  location: {
    latitude: number;
    longitude: number;
  };

  description?: string[] | null; //chatgpt

  mission?: string | null;
  adoptionInfo?: AdoptionInfo;
  socialMedia?: SocialMedia;
  photos?: PhotoMedia[];
  externalUrl?: string;
}

export interface Address {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode: string;
  country?: string | null;
}

export interface Hours {
  monday: string | null;
  tuesday: string | null;
  wednesday: string | null;
  thursday: string | null;
  friday: string | null;
  saturday: string | null;
  sunday: string | null;
}

export interface AdoptionInfo {
  policy: string | null;
  url: string | null;
}

export interface SocialMedia {
  facebook?: string | null;
  twitter?: string | null;
  youtube?: string | null;
  instagram?: string | null;
  pinterest?: string | null;
}

export const DEFAULT_PAGE_SIZE = 10;
export const PaginationSchema = z.object({
  limit: z.number().min(1).max(100).default(DEFAULT_PAGE_SIZE),
  cursor: z.string().optional(),
});

export const IdSchema = z.object({ id: z.string() });

export const LocationSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

export type Location = z.infer<typeof LocationSchema>;

export const NamedLocationSchema = LocationSchema.merge(
  z.object({
    name: z.string(),
    postalCode: z.string().optional(),
  }),
);

export type NamedLocation = z.infer<typeof NamedLocationSchema>;

const AdoptionStatuses = z.enum(['available', 'pending', 'adopted', 'unknown']);
export type AdoptionStatusType = z.infer<typeof AdoptionStatuses>;
export const allAdoptionStatuses = AdoptionStatuses.options;
export function isAdoptionStatusType(
  input: unknown,
): input is AdoptionStatusType {
  return AdoptionStatuses.safeParse(input).success;
}

const sexes = z.enum(['male', 'female']);
export type SexType = z.infer<typeof sexes>;
export const allSexes = sexes.options;
export function isSexType(input: unknown): input is SexType {
  return sexes.safeParse(input).success;
}

const ages = z.enum(['baby', 'young', 'adult', 'senior']);
export type AgeType = z.infer<typeof ages>;
export const allAges = ages.options;
export function isAgeType(input: unknown): input is AgeType {
  return ages.safeParse(input).success;
}

const sizes = z.enum(['small', 'medium', 'large', 'xlarge']);
export type SizeType = z.infer<typeof sizes>;
export const allSizes = sizes.options;
export function isSizeType(input: unknown): input is SizeType {
  return sizes.safeParse(input).success;
}

const envOptions = z.object({
  children: z.boolean().optional(),
  dogs: z.boolean().optional(),
  cats: z.boolean().optional(),
});
export type EnvOptionsType = keyof z.infer<typeof envOptions>;

export const SearchFilterSchema = z.object({
  sex: z.array(sexes).optional(),
  age: z.array(ages).optional(),
  size: z.array(sizes).optional(),
  breed: z.array(z.string()).optional(),
  color: z.array(z.string()).optional(),
  environment: envOptions.optional(),
  specialNeeds: z.boolean().optional(),
  houseTrained: z.boolean().optional(),
  adoptionStatus: AdoptionStatuses.optional(),
});

export type SearchFilter = z.infer<typeof SearchFilterSchema>;

export const DEFAULT_DISTANCE = 25;
export const MAX_DISTANCE = 100;
