import type { AppRouter } from '@server/api';
import config from '../config';
import { createTRPCReact, httpBatchLink } from '@trpc/react-query';

// Define the base URL for your tRPC server
const getBaseUrl = () => {
  console.log('getBaseUrl', config.apiUrl);
  return config.apiUrl;
};

// Create tRPC hooks
export const client = createTRPCReact<AppRouter>();

// Create a tRPC client
export const publicTrpcClient = client.createClient({
  links: [
    httpBatchLink({
      url: `${getBaseUrl()}`,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          // credentials: 'include',
        });
      },
      // headers() {
      //   return {
      //     [VISITOR_ID_HEADER]: uniqueVisitorId,
      //   };
      // },
    }),
  ],
});
