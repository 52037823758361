import React from 'react';
import { CARD_IMAGE_WIDTH, photoUrl, titleCase } from '../../utils/index';

export interface PortraitProps {
  petName: string;
  profilePhotoUrl: string;
  handleImageLoad?: () => void;
  preload?: boolean;
}

export const PetPortrait: React.FC<PortraitProps> = ({
  petName,
  profilePhotoUrl,
  handleImageLoad = () => {
    //no-op
  },
  preload = false,
}) => {
  const imageUrl = photoUrl(profilePhotoUrl, 'photo', CARD_IMAGE_WIDTH);
  if (!imageUrl) {
    return null;
  }

  const style: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const name = titleCase(petName);
  const altText = name ? `Portrait of ${name}` : 'Portrait of a pet';

  if (preload) {
    return (
      <div style={{ display: 'none' }}>
        <img src={imageUrl} alt={altText} onLoad={handleImageLoad} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center bg-light">
      <div className="flex flex-col items-center border-dark px-4 pt-4 pb-1 border-8 m-4 shadow-portrait bg-white rounded-sm">
        <img
          src={imageUrl}
          alt={altText}
          className="max-h-[50vh] shadow-inner mb-1 border-2 border-neutral-300"
          style={style}
        />
        <h3 className="text-dark bg-metallic-gold px-8 text-2xl font-beau font-bold shadow-namePlate">
          {name}
        </h3>
      </div>
    </div>
  );
};
