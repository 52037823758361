import React, { useEffect } from 'react';
import Search from '../pets/Search';
import { PetType } from '@prp/shared';
import { LocationFromBrowser } from '../utils/LocationFromBrowser';
import { useSaveScrollPosition } from '../../hooks/useSaveScrollPosition';
import { getTitle, PET_TYPE_KEY } from '../../utils/index';
import { useLocalStorage } from 'usehooks-ts';

export const Cats: React.FC = () => {
  useSaveScrollPosition();
  const [petType, setPetType] = useLocalStorage<PetType>(PET_TYPE_KEY, 'cat');
  useEffect(() => {
    setPetType('cat');
  }, [setPetType]);

  document.title = getTitle('Cats');
  return (
    <div className="h-screen">
      {/* the outer div + h-screen prevents a white flash on refresh */}
      <LocationFromBrowser>
        <Search petType={petType} />
      </LocationFromBrowser>
    </div>
  );
};
