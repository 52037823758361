import { useRef, useEffect } from 'react';
import ScrollWatcherInstance from '../components/utils/ScrollWatcher';
import { UseTRPCInfiniteQueryResult } from '@trpc/react-query/shared';
import { InfiniteData } from '@tanstack/react-query';

const LOAD_MORE_THRESHOLD = 1.5;

export const useInfiniteScrollQuery = <T>(
  queryFn: UseTRPCInfiniteQueryResult<T, any>,
  componentPath: string,
  scrollElement = document.documentElement,
): {
  data: InfiniteData<T> | undefined;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  loadMore: () => void;
  hasNextPage: boolean | undefined;
} => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    queryFn;

  const hasNextPageRef = useRef(hasNextPage);
  const isFetchingNextPageRef = useRef(isFetchingNextPage);
  const fetchNextPageRef = useRef(fetchNextPage);

  // Function to load more data
  const loadMore = () => {
    if (hasNextPageRef.current && !isFetchingNextPageRef.current) {
      fetchNextPageRef.current().catch((error: Error) => {
        console.error('Error loading more', error);
      });
    }
  };

  // Check if we need to load more data initially
  useEffect(() => {
    hasNextPageRef.current = hasNextPage;
    isFetchingNextPageRef.current = isFetchingNextPage;
    fetchNextPageRef.current = fetchNextPage;
    onScroll(componentPath);
  }, [data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage]);

  // Scroll event handler
  const onScroll = (currentPath: string) => {
    if (componentPath !== currentPath) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollElement;
    const bottomOfVisibleContent = scrollTop + clientHeight;
    const contentNotShown = scrollHeight - bottomOfVisibleContent;
    const contentNotShownAsViewportPercentage = contentNotShown / clientHeight;
    if (contentNotShownAsViewportPercentage < LOAD_MORE_THRESHOLD) {
      loadMore();
    }
  };

  useEffect(() => {
    const scrollObserver = (change: number, path: string) => {
      onScroll(path);
    };
    ScrollWatcherInstance.addListener(scrollObserver);
    return () => {
      ScrollWatcherInstance.removeListener(scrollObserver);
    };
    // eslint-disable-next-line
  }, []);

  return { data, isLoading, isFetchingNextPage, loadMore, hasNextPage };
};
