import { Address, OrganizationSummary, PetType } from '@prp/shared';
import React from 'react';
import OrgIcon from './OrgIcon';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

type OrganizationRowProps = {
  org: OrganizationSummary;
  isSelected: boolean;
  petType: PetType;
  handleClick: (orgId: string) => void;
};

const getAddress = (address: Address) => {
  const { address1, address2, city, state, postcode } = address;
  const addressLine3 = `${city}, ${state} ${postcode}`;
  return (
    <div className="flex flex-col">
      {address1 && <div>{address1}</div>}
      {address2 && <div>{address2}</div>}
      <div>{addressLine3}</div>
    </div>
  );
};

const OrganizationRow: React.FC<OrganizationRowProps> = ({
  org,
  isSelected,
  petType,
  handleClick,
}) => {
  const text = org.blurb;

  const hasNoPetsOfType = petType === 'dog' ? !org.dogs : !org.cats;

  return (
    <div
      className={classNames('flex-col items-center p-4 border-b ', {
        'bg-light boarder-dark': isSelected,
        'border-gray-300': !isSelected,
      })}
    >
      <div
        className={classNames('flex', {
          'cursor-pointer': !isSelected,
        })}
        onClick={() => handleClick(org.id)}
      >
        <OrgIcon
          orgName={org.name}
          orgId={org.id}
          isSelected={isSelected}
          isDisabled={hasNoPetsOfType}
        />
        <div className="ml-4 flex flex-col flex-grow">
          <h3 className="text-lg font-semibold flex-grow">{org.name}</h3>
          <p className="text-gray-600 line-clamp-2">{text}</p>
        </div>
      </div>
      {isSelected && (
        <div className="flex items-center pt-4 justify-around">
          <div className="flex flex-col text-gray-600">
            {getAddress(org.address)}
          </div>
          <Link to={`/org/detail/${org.id}`}>
            <button className="ml-4 bg-primary text-white px-4 py-2 rounded">
              View
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default OrganizationRow;
