import { nanoidToNumberInRange } from './index';

export function boundedNormalDistValue(
  nanoid: string,
  min: number,
  max: number,
): number {
  // Helper function to get a seeded random number generator
  function seededRandom(seed: number) {
    const m = 0x80000000; // 2**31;
    const a = 1103515245;
    const c = 12345;
    let state = seed ? seed : Math.floor(Math.random() * (m - 1));
    return function () {
      state = (a * state + c) % m;
      return state / (m - 1);
    };
  }

  const seed = nanoid
    .split('')
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const random = seededRandom(seed);

  // Box-Muller transform to generate a standard normal distributed number
  function normalDistribution() {
    let u = 0,
      v = 0;
    while (u === 0) u = random(); // Converting [0,1) to (0,1)
    while (v === 0) v = random();
    return Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  }

  // Generate a normally distributed value
  let value = normalDistribution();

  // Scale and translate to fit the desired range
  const mean = (max + min) / 2;
  const stdDev = (max - min) / 6; // 99.7% of values will fall within ±3 stdDev from the mean

  const sign = nanoidToNumberInRange(nanoid, 0, 1) ? -1 : 1;
  value = sign * value * stdDev + mean;

  // Clamp the value to ensure it falls within the specified range
  value = Math.max(min, Math.min(max, value));

  return Math.round(value);
}
