import React from 'react';
import { Link } from 'react-router-dom';
import { Organization } from '@prp/shared';
import { createSafeURL, getFirstPhotoUrl } from '../../utils/index';
import OrgIcon from './OrgIcon';

const ORG_IMAGE_WIDTH = 300;

type OrgParams = {
  org: Organization;
};

export const OrgProfile: React.FC<OrgParams> = ({ org }) => {
  const photoUrl = getFirstPhotoUrl(org);

  const urlProfile = photoUrl ? createSafeURL(photoUrl) : null;
  if (urlProfile) {
    urlProfile.searchParams.append('width', ORG_IMAGE_WIDTH.toString());
  }
  const imageUrl = urlProfile ? urlProfile.toString() : null;

  return (
    <div className="flex flex-col justify-between bg-light m-4">
      <div className="flex-col items-center bg-white p-4">
        <div className="text-xl bg-secondary text-white">In the Care of</div>
        <div className="text-lg font-semibold">{org.name}</div>
        <div className="flex items-center justify-around px-4">
          <OrgIcon orgId={org.id} orgName={org.name} />
          <Link to={`/org/detail/${org.id}`}>
            <button className="bg-primary text-xl text-white px-4 py-2 rounded m-1">
              View Organization
            </button>
          </Link>
        </div>
      </div>
      {imageUrl && (
        <div className="mt-8 p-4 bg-white">
          <img src={imageUrl} alt={org.name} className="w-[300px]" />
        </div>
      )}
    </div>
  );
};
