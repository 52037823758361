import React, { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { useNavigationContext } from '../../state/NavigationContext';

const CustomScrollHandler: React.FC = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  const { getScrollPosition } = useNavigationContext();

  useEffect(() => {
    if (navigationType === 'PUSH' || navigationType === 'REPLACE') {
      const scrollPosition = getScrollPosition(location.pathname);
      window.scrollTo(0, scrollPosition);
    }
  }, [location, navigationType, getScrollPosition]);

  return null;
};

export default CustomScrollHandler;
