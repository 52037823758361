import React, { useContext, useEffect, useRef, useState } from 'react';
import { FilterContext } from '../../state/FilterContext';
import {
  AgeType,
  allAges,
  allSexes,
  allSizes,
  EnvOptionsType,
  PetType,
  SexType,
  SizeType,
} from '@prp/shared';
import FilterGroup from '../utils/FilterGroup';
import { FaXmark } from 'react-icons/fa6';
import { useLocalStorage } from 'usehooks-ts';
import { PET_TYPE_KEY } from '../../utils/index';

type ComponentProps = {
  isFilterPaneOpen: boolean;
  setIsFilterPaneOpen: (isOpen: boolean) => void;
};

const FilterPane: React.FC<ComponentProps> = ({
  isFilterPaneOpen,
  setIsFilterPaneOpen,
}) => {
  const filterContext = useContext(FilterContext);
  const [filterState, setFilterState] = useState(filterContext.searchFilter);
  const scrollableRef = useRef<HTMLDivElement>(null);

  const [petType] = useLocalStorage<PetType>(PET_TYPE_KEY, 'dog');

  useEffect(() => {
    if (isFilterPaneOpen) {
      // If the filter pane is open, reset the scroll position of the .scrollable element
      if (scrollableRef.current) {
        scrollableRef.current.scrollTop = 0;
      }
    }
  }, [isFilterPaneOpen]);

  useEffect(() => {
    setFilterState(filterContext.searchFilter);
  }, [filterContext.searchFilter]);

  const applyChange = () => {
    setIsFilterPaneOpen(false);
    filterContext.setSearchFilter(filterState);
  };

  const cancelChange = () => {
    setIsFilterPaneOpen(false);
    setFilterState(filterContext.searchFilter);
  };

  const clearFilters = () => {
    setFilterState({});
  };

  const updateSexStatus = (option: SexType, selectionStatus: boolean) => {
    const newSexStatus = new Set<SexType>(filterState.sex || []);
    if (selectionStatus) {
      newSexStatus.add(option);
    } else {
      newSexStatus.delete(option);
    }
    setFilterState({ ...filterState, sex: Array.from(newSexStatus) });
  };

  const updateAgeStatus = (option: AgeType, selectionStatus: boolean) => {
    const newAgeStatus = new Set<AgeType>(filterState.age || []);
    if (selectionStatus) {
      newAgeStatus.add(option);
    } else {
      newAgeStatus.delete(option);
    }
    setFilterState({ ...filterState, age: Array.from(newAgeStatus) });
  };

  const updateSizeStatus = (option: SizeType, selectionStatus: boolean) => {
    const newSizeStatus = new Set<SizeType>(filterState.size || []);
    if (selectionStatus) {
      newSizeStatus.add(option);
    } else {
      newSizeStatus.delete(option);
    }
    setFilterState({ ...filterState, size: Array.from(newSizeStatus) });
  };

  const updateGoodWithStatus = (
    option: EnvOptionsType,
    selectionStatus: boolean,
  ) => {
    const newEnv = { ...filterState.environment };
    if (option) {
      newEnv[option] = selectionStatus;
    } else {
      delete newEnv[option];
    }
    setFilterState({ ...filterState, environment: newEnv });
  };

  const goodWithOptions: [EnvOptionsType, string, boolean][] = [
    ['children', 'children', filterState.environment?.children || false],
    ['dogs', 'dogs', filterState.environment?.dogs || false],
    ['cats', 'cats', filterState.environment?.cats || false],
  ];

  const sexOptions: [SexType, string, boolean][] = allSexes.map((option) => [
    option,
    option,
    new Set(filterState.sex)?.has(option),
  ]);
  const babyLabel = petType === 'dog' ? 'puppy' : 'kitten';
  const ageOptions: [AgeType, string, boolean][] = allAges.map((option) => {
    const label = option === 'baby' ? babyLabel : option;
    return [option, label, new Set(filterState.age).has(option)];
  });
  const sizeOptions: [SizeType, string, boolean][] = allSizes.map((option) => [
    option,
    option,
    new Set(filterState.size).has(option),
  ]);

  return (
    <div
      id="filter-pane"
      className={isFilterPaneOpen ? 'modal-background' : ''}
      onClick={cancelChange}
    >
      <div
        className={`filter-pane ${isFilterPaneOpen ? 'open' : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="header">
          <div className="filter-title">Filters</div>
          <div
            className="close-button absolute p-2 right-1 cursor-pointer"
            onClick={cancelChange}
          >
            <FaXmark className="h-6 w-6" />
          </div>
        </div>
        <div className="filter-controls">
          <button
            id="filter-clear"
            className="toggle-button"
            onClick={clearFilters}
          >
            Clear
          </button>
          <button
            id="filter-apply"
            className="toggle-button"
            onClick={() => applyChange()}
          >
            Apply
          </button>
        </div>
        <div className="scrollable" ref={scrollableRef}>
          <FilterGroup
            name="Sex"
            options={sexOptions}
            updateStatus={updateSexStatus}
          />
          <FilterGroup
            name="Age"
            options={ageOptions}
            updateStatus={updateAgeStatus}
          />
          <FilterGroup
            name="Size"
            options={sizeOptions}
            updateStatus={updateSizeStatus}
          />
          <FilterGroup
            name="Good With"
            options={goodWithOptions}
            updateStatus={updateGoodWithStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterPane;
