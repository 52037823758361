import React from 'react';

const NoPetsFound: React.FC = () => {
  return (
    <div className="flex items-center justify-center m-4">
      <div className="bg-gray-200 p-4 rounded shadow-md">
        <h2 className="text-xl font-bold mb-2">No Pets Found</h2>
        <p>No pets match the current filter</p>
      </div>
    </div>
  );
};

export default NoPetsFound;
