import React from 'react';
import { Media } from './Media';
import { PetProfile } from '@prp/shared';

type ExtMediaListParams = {
  profile: PetProfile;
};

export const ExtMediaList: React.FC<ExtMediaListParams> = ({ profile }) => {
  return (
    <div className="flex flex-column flex-grow">
      <div className="flex flex-grow flex-wrap justify-evenly items-center">
        {profile.extPhotos?.map((post) => (
          <div key={post.url} className="post">
            <Media media={post} />
          </div>
        ))}
      </div>
    </div>
  );
};
