import React, { useEffect, useState } from 'react';
import { client, ERROR_DETAILS_KEY, ErrorDetails } from '../../utils/index';
import { FaBug } from 'react-icons/fa';
import { Footer } from '../layout/Footer';
import PageLabel from '../utils/PageLabel';

const ErrorFallback: React.FC = () => {
  const [errorDetails, setErrorDetails] = useState<ErrorDetails | null>(null);

  const mutation = client.logClientError.useMutation();

  useEffect(() => {
    const storedError = sessionStorage.getItem(ERROR_DETAILS_KEY);
    if (storedError) {
      const errorDetails: ErrorDetails = JSON.parse(storedError);
      setErrorDetails(errorDetails);
      // Clean up after reading
      sessionStorage.removeItem(ERROR_DETAILS_KEY);

      const stack = errorDetails.errorInfo.componentStack || '';
      const message = errorDetails.error.message;
      mutation.mutate({ message, stack });
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-20 pb-2 -mx-4">
          <PageLabel title={"Well that's not supposed to happen"} />
        </div>
        <h1 className="flex justify-center items-center m-4 text-xl">
          <FaBug className="mr-1 h-6 w-6" /> Sorry, something went wrong
          there...
        </h1>
        <div>
          <button
            onClick={() => (window.location.href = '/')}
            className="my-4 px-4 py-2 bg-blue-500 text-white rounded"
          >
            Return Home
          </button>
        </div>
        {errorDetails && (
          <div className="text-left">
            <pre className="bg-gray-100 px-4 pt-4 pb-2">
              <p className="font-bold">Error: {errorDetails.error.message}</p>
              {errorDetails.errorInfo.componentStack}
            </pre>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ErrorFallback;
