import React, {
  createContext,
  useContext,
  useRef,
  useCallback,
  useState,
} from 'react';

type ComponentProps = {
  children: React.ReactNode;
};

interface NavigationContextType {
  registerCallback: (callback: NavCallback) => () => void;
  getCallbacks: () => NavCallback[];
  getScrollPosition: (path: string) => number;
  setScrollPosition: (path: string, value: number) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(
  undefined,
);

export type NavCallback = ({ isReload }: { isReload: boolean }) => void;

export const NavigationProvider: React.FC<ComponentProps> = ({ children }) => {
  const [scrollPositionMap, setScrollPositionMap] = useState<
    Map<string, number>
  >(new Map());

  const setScrollPosition = (key: string, value: number) => {
    setScrollPositionMap((prevMap) => new Map(prevMap).set(key, value));
  };

  const getScrollPosition = (key: string) => {
    return scrollPositionMap.get(key) || 0;
  };

  const callbacks = useRef<NavCallback[]>([]);

  const registerCallback = useCallback((callback: NavCallback) => {
    callbacks.current.push(callback);
    return () => {
      callbacks.current = callbacks.current.filter((cb) => cb !== callback);
    };
  }, []);

  const getCallbacks = () => callbacks.current;

  return (
    <NavigationContext.Provider
      value={{
        registerCallback,
        getScrollPosition,
        setScrollPosition,
        getCallbacks,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error(
      'useNavigationContext must be used within a NavigationProvider',
    );
  }
  return context;
};
