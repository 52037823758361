import React from 'react';
import { MediaType } from '@prp/shared';
import { createSafeURL } from '../../utils/index';

const CARD_IMAGE_WIDTH = 300;

export const Media: React.FC<{ media: MediaType }> = ({ media }) => {
  const urlCard = createSafeURL(media.url);
  if (!urlCard) {
    return null;
  }

  if (media.mediaType === 'photo') {
    urlCard.searchParams.append('width', CARD_IMAGE_WIDTH.toString());
  }
  const imageUrl = urlCard.toString();
  const altText = media.altText || 'Pet in need of adoption';
  return (
    <div className="bg-white m-4 p-4">
      <img src={imageUrl} alt={altText} loading="lazy" />
    </div>
  );
};
