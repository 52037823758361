import React from 'react';
import { Footer } from '../layout/Footer';
import { formatToLocalTime, getTitle } from '../../utils';
import config from '../../config';
import PageLabel from '../utils/PageLabel';

const About = () => {
  document.title = getTitle('About');
  const buildTime =
    config.appEnv === 'dev' ? new Date().toISOString() : config.buildTime;
  const formattedBuildTime = formatToLocalTime(buildTime);
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-20 pb-2 -mx-4">
          <PageLabel title={'About'} />
        </div>
        <div>
          <section className="flex flex-col md:flex-row md:mx-10 items-center md:justify-around">
            <div className="bg-secondary p-4 m-8">
              <img
                src={`${config.cdnUrl}/miles.jpg`}
                alt="A good looking cat"
                className="min-w-[300px]"
              />
            </div>
            <div className="bg-dark p-4 text-white m-8">
              <h1 className="title mb-4">Origin Story</h1>
              <div className="text-lg text-left">
                <p className="mb-8">
                  This project was born out of the idea that we can do better at
                  addressing the homeless pet population. There are many many
                  compassionate people volunteering their time to care and
                  nurture cats and dogs in order to help them find their forever
                  home. However an animal's introduction to potential adopters
                  all to often takes place in a noisy and stressful environment.
                  That, in combination with the limited time of adoption events,
                  rarely allows a pet's true personality to be seen by potential
                  adopters.
                </p>
                <p>
                  Pet Rescue Plus allows caregivers to share each pet's
                  individual personality with potential adopters in order to
                  maximize the chance of successful match.
                </p>
              </div>
            </div>
          </section>
        </div>
        <div id="version-info" className="text-2xl text-dark">
          <p>Matching pets and people since 2021</p>
          <p className="text-base mt-2">
            v{config.version} - {formattedBuildTime}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
