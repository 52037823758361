import { useNavigationContext } from '../state/NavigationContext';
import useNavigationCallback from './useNavigationCallback';

export const useSaveScrollPosition = (): void => {
  const { setScrollPosition } = useNavigationContext();
  const saveScrollPosition = ({ isReload = false }) => {
    const scrollPosition = isReload ? 0 : window.scrollY;
    setScrollPosition(window.location.pathname, scrollPosition);
  };
  useNavigationCallback(saveScrollPosition);
};
