const LoadingState = () => {
  //based on the tutorial: https://www.youtube.com/watch?v=zAbnlisUFZE
  const delays = [0, 0.2, 0.4, 0.6, 0.8];

  return (
    <div className="flex items-center justify-center">
      <div className="flex space-x-2">
        {delays.map((delay, index) => (
          <div
            key={index}
            className="h-5 w-5 rounded-full inline-block m-2 animate-scaling"
            style={{ animationDelay: `${delay}s` }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default LoadingState;
