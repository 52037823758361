import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import './tailwind.css';
import App from './App';
// import config from './config';

const root = ReactDOM.createRoot(document.querySelector('#root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// const { appEnv, version } = config;
//
// const loadCoralogixSDK = () => {
//   const script = document.createElement('script');
//   script.src =
//     'https://cdn.rum-ingress-coralogix.com/coralogix/browser/latest/coralogix-browser-sdk.js';
//   script.onload = () => {
//     window.CoralogixRum.init({
//       application: 'prp-web-client',
//       environment: appEnv,
//       public_key: 'cxtp_KwXnzwxS1FAcsoBfebDr7GfiLomXul',
//       coralogixDomain: 'US2',
//       version: version,
//     });
//   };
//   document.head.appendChild(script);
// };
//
// if (appEnv === 'prod') {
//   //this is kind of verbose
//   loadCoralogixSDK();
// }
//
// declare global {
//   interface Window {
//     CoralogixRum: any;
//   }
// }
