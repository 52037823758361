import React from 'react';
import { OrgSource } from '@prp/shared';

interface AttributionProps {
  source: OrgSource;
}

const getLabel = (source: OrgSource) => {
  switch (source) {
    case 'PetFinder':
      return 'Powered by Petfinder';
    case 'RescueGroups':
      return 'Powered by RescueGroups';
  }
  return '';
};

const Attribution: React.FC<AttributionProps> = ({ source }) => {
  const label = getLabel(source);
  return (
    <div className="bg-white text-lg text-secondary italic font-semibold mt-12 p-1">
      <div>{label}</div>
    </div>
  );
};

export default Attribution;
