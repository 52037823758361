import React from 'react';
import { PhotoCardMedia } from '@prp/shared';
import { PhotoCard } from './pets/PhotoCard';
import LoadingState from './layout/LoadingState';
import NoPetsFound from './utils/NoPetsFound';

export interface GalleryProps {
  media: PhotoCardMedia[];
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  fadeIn?: boolean;
  showOrgSticker?: boolean;
}

const Gallery: React.FC<GalleryProps> = ({
  media,
  isLoading,
  hasNextPage,
  fadeIn,
  showOrgSticker,
}) => {
  const displayList = () => {
    return (
      <div className="flex-grow flex flex-col bg-light">
        {/*{error && <h3>{error}</h3>}*/}
        <div id="gallery">
          {media.map((petMedia, i) => (
            <PhotoCard
              key={petMedia.url}
              petMedia={petMedia}
              fadeIn={fadeIn}
              showOrgSticker={showOrgSticker}
            />
          ))}
        </div>
        <div className="flex-grow flex items-center justify-center">
          {isLoading && <LoadingState />}
          {hasNextPage === false && media.length === 0 && <NoPetsFound />}
        </div>
      </div>
    );
  };

  return displayList();
};

export default Gallery;
