import buildInfo from './build-info.json';

const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  cdnUrl: process.env.REACT_APP_CDN_URL,
  appEnv: process.env.REACT_APP_ENV || 'dev',
  version: buildInfo.version,
  buildTime: buildInfo.buildTime,
};

export default config;
