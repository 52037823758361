import React from 'react';

interface PetAttributeProps {
  label: string;
  value?: boolean | string | null;
}

const PetAttribute: React.FC<PetAttributeProps> = ({ label, value }) => {
  if (!value) {
    return null;
  }

  const valueText = typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
  return (
    <li className="mb-1">
      <span className="font-bold">{label}:</span>{' '}
      <span className="capitalize">{valueText}</span>
    </li>
  );
};

export default PetAttribute;
