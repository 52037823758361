import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of our context
interface UserActionContextType {
  clickedPhotoPetIds: Set<string>;
  viewedPhotoPetIds: Set<string>;
  viewedProfilePetIds: Set<string>;
  animationStartedPhotoPetIds: Set<string>;
  animationCompletedPhotoPetIds: Set<string>;
  addClickedPhotoPetId: (petId: string) => void;
  addViewedPhotoPetId: (petId: string) => void;
  addViewedProfilePetId: (petId: string) => void;
  addPhotoAnimationStarted: (petId: string, animationTime: number) => void;
  addPhotoAnimationCompleted: (petId: string) => void;
  viewedAdoptedPet: boolean;
  setViewedAdoptedPet: (status: boolean) => void;
}

// Create the context with a default value
const UserActionContext = createContext<UserActionContextType | undefined>(
  undefined,
);

// Create a provider component
export const UserActionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [viewedPhotoPetIds, setViewedPhotoPetIds] = useState<Set<string>>(
    new Set(),
  );
  const [viewedProfilePetIds, setViewedProfilePetIds] = useState<Set<string>>(
    new Set(),
  );

  const [clickedPhotoPetIds, setClickedPhotoPetIds] = useState<Set<string>>(
    new Set(),
  );

  const [animationStartedPhotoPetIds, setAnimationStartedPhotoPetIds] =
    useState<Set<string>>(new Set());

  const [animationCompletedPhotoPetIds, setAnimationCompletedPhotoPetIds] =
    useState<Set<string>>(new Set());

  const addClickedPhotoPetId = (petId: string) => {
    setClickedPhotoPetIds((prevSet) => new Set(prevSet).add(petId));
  };

  const addViewedPhotoPetId = (petId: string) => {
    setViewedPhotoPetIds((prevSet) => new Set(prevSet).add(petId));
  };

  const addViewedProfilePetId = (petId: string) => {
    setViewedProfilePetIds((prevSet) => new Set(prevSet).add(petId));
  };

  const addPhotoAnimationStarted = (petId: string, animationTime: number) => {
    setAnimationStartedPhotoPetIds((prevSet) => new Set(prevSet).add(petId));
    setTimeout(() => {
      addPhotoAnimationCompleted(petId);
    }, animationTime * 1000);
  };

  const addPhotoAnimationCompleted = (petId: string) => {
    setAnimationCompletedPhotoPetIds((prevSet) => new Set(prevSet).add(petId));
  };

  const [viewedAdoptedPet, setViewedAdoptedPet] = useState(false);

  return (
    <UserActionContext.Provider
      value={{
        clickedPhotoPetIds,
        viewedPhotoPetIds,
        viewedProfilePetIds,
        animationStartedPhotoPetIds,
        animationCompletedPhotoPetIds,
        addClickedPhotoPetId,
        addViewedPhotoPetId,
        addViewedProfilePetId,
        addPhotoAnimationStarted,
        addPhotoAnimationCompleted,
        viewedAdoptedPet,
        setViewedAdoptedPet,
      }}
    >
      {children}
    </UserActionContext.Provider>
  );
};

// Create a custom hook to use the UserActionContext
export const useUserActionContext = (): UserActionContextType => {
  const context = useContext(UserActionContext);
  if (context === undefined) {
    throw new Error(
      'useUserActionContext must be used within a UserActionProvider',
    );
  }
  return context;
};
