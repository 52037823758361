import React from 'react';
import { PetType } from '@prp/shared';
import { LuCat } from 'react-icons/lu';
import { useLocalStorage } from 'usehooks-ts';
import { PET_TYPE_KEY } from '../../utils/index';
import clsx from 'clsx';
import { PiDogBold } from 'react-icons/pi';

export const getFilterButtonClasses = (isActive: boolean) => {
  const baseClasses =
    'text-white text-2xl p-2 rounded-md cursor-pointer shadow-lg';
  const activeClasses = 'bg-primary';
  const inactiveClasses = 'bg-secondary';
  const hoverClasses = 'lg:hover:bg-dark'; //hover not clearing on touch devices

  return clsx(
    baseClasses,
    { [activeClasses]: isActive },
    { [inactiveClasses]: !isActive },
    hoverClasses,
  );
};

const PetTypeSelector = () => {
  const [petType, setPetType] = useLocalStorage<PetType>(PET_TYPE_KEY, 'dog');

  return (
    <div className="fixed top-16 right-4 md:right-5 flex flex-col space-y-4 z-10">
      <button
        className={getFilterButtonClasses(petType === 'dog')}
        onClick={() => setPetType('dog')}
      >
        <PiDogBold />
      </button>
      <button
        className={getFilterButtonClasses(petType === 'cat')}
        onClick={() => setPetType('cat')}
      >
        <LuCat />
      </button>
    </div>
  );
};

export default PetTypeSelector;
