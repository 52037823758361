import React from 'react';
import { Footer } from '../layout/Footer';
import { getTitle } from '../../utils';
import { FaBug } from 'react-icons/fa';

const NotFound: React.FC = () => {
  document.title = getTitle('???');
  return (
    <div className="page">
      <div className="content">
        <div className="label">
          <h2>Not Found</h2>
        </div>
        <h1 className="flex justify-center items-center">
          <FaBug className="mr-1" /> Sorry, something went wrong there...
        </h1>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
