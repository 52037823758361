import React, {
  ChangeEvent,
  ChangeEventHandler,
  useContext,
  useState,
} from 'react';
import { LocationContext } from '../../state/LocationContext';
import { client } from '../../utils';
import { NamedLocation } from '@prp/shared';
import { FaXmark } from 'react-icons/fa6';

export const UNKNOWN_LOCATION = 'Unknown';
export const MOHAWK_HUDSON: NamedLocation = {
  name: 'Menands, NY',
  postalCode: '12204',
  latitude: 42.6890629,
  longitude: -73.7264916,
};

const Location = () => {
  const [textLocation, setTextLocation] = useState<string>('');

  const { showLocationModal, setShowLocationModal, setLocation } =
    useContext(LocationContext);

  const { refetch } = client.location.getLocationForName.useQuery(
    textLocation,
    {
      enabled: false,
    },
  );

  const locationModal = document.getElementById('location-modal');
  if (locationModal) {
    if (showLocationModal) {
      const inputField = document.getElementById(
        'location-text',
      ) as HTMLInputElement;
      if (locationModal.classList.contains('hide-prompt')) {
        //just do once
        inputField.value = '';
      }
      locationModal.classList.remove('hide-prompt');
      locationModal.classList.add('show-prompt');
      inputField.focus();
    } else {
      locationModal.classList.remove('show-prompt');
      locationModal.classList.add('hide-prompt');
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (textLocation.trim().length > 0) {
      refetch()
        .then(({ data }) => {
          if (data) {
            setLocation(data);
            setTextLocation('');
            setShowLocationModal(false);
          }
        })
        .catch((err) => {
          console.log('refetch error', err);
        });
    }
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
  ): ChangeEventHandler<HTMLInputElement> | undefined => {
    const textVal = event.target.value;
    if (textVal.trim().length > 0) {
      setTextLocation(event.target.value);
    } else {
      event.target.value = '';
    }
    return this;
  };

  const cancelChange = () => {
    setShowLocationModal(false);
  };

  const keepFocus = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Tab') {
      const inputField = document.getElementById(
        'location-text',
      ) as HTMLInputElement;
      inputField.focus();
      e.preventDefault();
    } else if (e.key === 'Escape') {
      cancelChange();
    }
  };

  const escape = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      cancelChange();
    }
  };

  return (
    <div
      id="location-modal"
      className="modal-background hide-prompt"
      onClick={cancelChange}
    >
      <div
        id="location-input"
        className="modal rounded shadow-lg bg-primary absolute text-white top-1/4 md:top-1/2 md:-translate-y-1/2"
        onClick={(e) => e.stopPropagation()}
      >
        <p
          className="close-button absolute p-2 right-0 cursor-pointer"
          onClick={cancelChange}
        >
          <FaXmark className="h-6 w-6" />
        </p>
        <h2 className="header text-2xl">Update Location</h2>
        <form id="address-form" onSubmit={handleSubmit}>
          <div className="address-group">
            <div className="form-input">
              <input
                type="text"
                id="location-text"
                aria-label="Address"
                className="form-control text-dark"
                placeholder="City & State or Zipcode"
                onChange={(e) => handleInputChange(e)}
                onKeyDown={(e) => escape(e)}
              />
            </div>
          </div>
          <button
            type="submit"
            className="m-2 px-4 py-2 bg-dark text-white text-xl rounded hover:bg-secondary"
            onKeyDown={(e) => keepFocus(e)}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Location;
