import React, { useContext } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { LocationContext } from '../../state/LocationContext';
import { MOHAWK_HUDSON } from './Location';
import { LIGHT_GRAY, SECONDARY_COLOR } from '../../utils/index';

const containerStyle = {
  width: '100%',
  height: '100%',
};

type Coords = { lat: number; lng: number };

type MapComponentProps = {
  markers: {
    id: string;
    lat: number;
    lng: number;
    color: string;
    text: string;
    isDisabled?: boolean;
  }[];
  selected: string | null;
  handleClick: (orgId: string) => void;
};

const calculateCenter = (coords: Coords[]) => {
  const numCoords = coords.length;
  const sumCoords = coords.reduce(
    (acc, coordinates) => {
      return {
        lat: acc.lat + coordinates.lat,
        lng: acc.lng + coordinates.lng,
      };
    },
    { lat: 0, lng: 0 },
  );

  return {
    lat: sumCoords.lat / numCoords,
    lng: sumCoords.lng / numCoords,
  };
};

const THIRTY_MILES_LAT = 0.35;
const THIRTY_MILES_LNG = 0.55;

const MapComponent: React.FC<MapComponentProps> = ({
  markers,
  selected,
  handleClick,
}) => {
  const locationContext = useContext(LocationContext);
  const location = locationContext.location
    ? locationContext.location
    : MOHAWK_HUDSON;

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey || 'MISSING_API_KEY',
  });

  const markerPosition = {
    lat: location.latitude,
    lng: location.longitude,
  };
  const center = markers.length > 0 ? calculateCenter(markers) : markerPosition;

  const createSvgMarker = (
    fillColor: string,
    text: string,
    isSelected: boolean,
    isDisabled?: boolean,
  ) => {
    const textColor = isDisabled ? LIGHT_GRAY : 'white';
    const boarderColor = isSelected ? SECONDARY_COLOR : textColor;
    return `
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="${boarderColor}" stroke-width="2" fill="${fillColor}" />
      <text x="12" y="12.5" font-size="5" font-family="Roboto, sans-serif" font-weight="bold" fill="${textColor}" text-anchor="middle" dominant-baseline="middle">${text}</text>
    </svg>
  `;
  };

  const createMarker = (marker: {
    id: string;
    lat: number;
    lng: number;
    color: string;
    text: string;
    isDisabled?: boolean;
  }) => {
    const isSelected = selected === marker.id;
    const size = isSelected ? 72 : 48;
    const svgMarker = createSvgMarker(
      marker.color,
      marker.text,
      isSelected,
      marker.isDisabled,
    );
    return (
      <Marker
        key={marker.id}
        position={{ lat: marker.lat, lng: marker.lng }}
        icon={{
          url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgMarker)}`,
          scaledSize: new window.google.maps.Size(size, size),
          anchor: new window.google.maps.Point(size / 2, size / 2),
        }}
        onClick={() => handleClick(marker.id)}
      />
    );
  };

  const bounds = {
    north: center.lat + THIRTY_MILES_LAT,
    south: center.lat - THIRTY_MILES_LAT,
    west: center.lng - THIRTY_MILES_LNG,
    east: center.lng + THIRTY_MILES_LNG,
  };
  const mapOptions = {
    mapTypeControl: false, // Hides the map/satellite buttons
    streetViewControl: false, // Hides the Street View person button
    restriction: {
      latLngBounds: bounds,
      strictBounds: false,
    },
  };

  return isLoaded ? (
    <div style={{ height: 'calc(100vh - 5rem)' }}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={mapOptions}
      >
        <Marker position={markerPosition} />
        {markers.map((marker) => createMarker(marker))}
        <></>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default MapComponent;
