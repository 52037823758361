import React from 'react';
import { Footer } from '../layout/Footer';
import { getTitle } from '../../utils';
import config from '../../config';
import PageLabel from '../utils/PageLabel';

const Resources = () => {
  document.title = getTitle('Resources');

  const openResource = (url: string): void => {
    window.open(url, '_blank');
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col flex-grow mx-4 bg-light">
        <div className="flex pt-20 pb-2 -mx-4">
          <PageLabel title={'Resources'} />
        </div>
        <section id="resource-info">
          <div className="card">
            <div className="info-img">
              <img
                src={`${config.cdnUrl}/resource1.jpg`}
                alt="A good looking dog"
              />
            </div>
            <div
              className="resource-text"
              onClick={() =>
                openResource(
                  'https://www.boredpanda.com/animal-shelter-photography-ocas-albert-harris',
                )
              }
            >
              <h3 className="title">The Difference a Good Photo Makes</h3>
              <p>
                While it shouldn't come as much of a surprise, the composition
                of photographs showcasing adoptable animals makes a tremendous
                difference in the kind of response it generates. As further
                proof of that, Florida’s Orange County Animal Services (OCAS)
                was able to break their 48 year old adoption record with the
                help of a professional photographer. Click to see how ...
              </p>
            </div>
          </div>
          <div className="card">
            <div className="info-img">
              <img
                src={`${config.cdnUrl}/resource2.jpg`}
                alt="A good looking dog"
              />
            </div>
            <div
              className="resource-text"
              onClick={() =>
                openResource(
                  'https://www.akc.org/expert-advice/training/clicker-training-your-dog-mark-and-reward/',
                )
              }
            >
              <h3 className="title">Clicker Training's on the Mark</h3>
              <p>
                Two constants of dogs are that they're food motivated and they
                want nothing more than to please their human. An excellent way
                to combined those two traits is with clicker training which is
                used to identify and reinforce positive behaviors. The clicker
                itself need not cost more than a couple dollars, which is
                nothing compared to the presentation of a well trained dog up
                for adoption. Click to see how ...
              </p>
            </div>
          </div>
          <div className="card">
            <div className="info-img">
              <img
                src={`${config.cdnUrl}/resource3.jpg`}
                alt="A good looking cat"
              />
            </div>
            <div
              className="resource-text"
              onClick={() =>
                openResource(
                  'https://www.catster.com/lifestyle/cat-adoption-10-tips',
                )
              }
            >
              <h3 className="title">Creativity can make the Difference</h3>
              <p>
                Not sure how to get those adoptable cats the attention they
                deserve? Getting creative can be a low cost way to help them
                find a forever home. Blocked on how to do that? No problem,
                there are lots of resources with ideas already available! Click
                to see more ...
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Resources;
