import React from 'react';
import Header from '../layout/Header';
import { Footer } from '../layout/Footer';
import { getTitle } from '../../utils';

const Home: React.FC = () => {
  document.title = getTitle();

  return (
    <div>
      <Header />
      <div className="p-4 bg-light text-white">
        <section className="flex flex-col md:flex-row md:items-stretch items-center bg-secondary my-4">
          <div className="flex flex-col text-lg m-4">
            <p className="text-4xl m-2">Our Mission</p>
            <div className="text-left">
              <p className="m-2 mb-4">
                Pet Rescue Plus was created to help find loving forever homes
                for the millions of animals surrendered in the US each year.
              </p>
              <p className="m-2 mb-4">
                We are here to support all the amazing shelter and rescue
                organizations by providing them with best tools available for
                raising awareness of the awesome cats and dogs in need of loving
                forever homes.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
