import LoadingState from '../layout/LoadingState';
import { Footer } from '../layout/Footer';
import React from 'react';

const LoadingPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow flex justify-center items-center">
        <LoadingState />
      </div>
      <Footer />
    </div>
  );
};

export default LoadingPage;
