
interface observerMethods {
  addListener: (observer : (change : number, path: string) => void) => void,
  removeListener: (observer : (change : number, path: string) => void) => void,
  updateLocation: (pathname : string) => void,
}

const ScrollWatcher: () => observerMethods = () => {
  const observers : ((change : number, path: string) => void)[] = [];
  let path = '';

  // console.log('created a new ScrollWatcher!')
  let lastScrollTop = 0;
  setInterval(() => {
    const {scrollTop} = document.documentElement;
    if(lastScrollTop !== scrollTop){
      const change = scrollTop - lastScrollTop;
      // console.log(`lastScrollTop: ${lastScrollTop} is not equal to: ${scrollTop}`);
      observers.forEach(fn => fn(change, path));
      lastScrollTop = scrollTop;
    }
  }, 250);

  const addListener = (observer : (change : number, path: string) => void) => {
    observers.push(observer);
  }

  const removeListener = (observer : (change : number, path: string) => void) => {
    observers.filter(fn => observer !== fn);
  }

  const updateLocation = (pathname : string) => {
    path = pathname;
  }

  return {
    addListener,
    removeListener,
    updateLocation,
  };
}

const ScrollWatcherInstance = ScrollWatcher();

export default ScrollWatcherInstance;