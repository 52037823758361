import React from 'react';
import { client } from '../../utils/index';
import { PetType, SearchFilter } from '@prp/shared';
import { useInfiniteScrollQuery } from '../../hooks/useInfiniteScrollQuery';
import { useLocation } from 'react-router-dom';
import Gallery from '../Gallery';

export interface PetListProps {
  orgId: string;
  petType: PetType;
  filter: SearchFilter;
}

const PetList: React.FC<PetListProps> = ({ orgId, petType, filter }) => {
  const queryFunction = client.pets.getAvailableByOrg.useInfiniteQuery(
    {
      id: orgId,
      petType,
      filter,
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
    },
  );

  const { data, isLoading, isFetchingNextPage, hasNextPage } =
    useInfiniteScrollQuery(queryFunction, useLocation().pathname);

  const media = data?.pages.map((group) => group.items).flat() || [];
  return (
    <div className="px-4">
      <Gallery
        media={media}
        isLoading={isLoading || isFetchingNextPage}
        hasNextPage={hasNextPage}
        fadeIn={false}
        showOrgSticker={false}
      />
    </div>
  );
};

export default PetList;
