import React, { useState } from 'react';
import FilterPane from '../layout/FilterPane';
import { isFilterSet } from '../../state/FilterContext';
import { SearchFilter } from '@prp/shared';
import { getFilterButtonClasses } from './PetTypeSelector';
import { LuListFilter } from 'react-icons/lu';

type PetAttributesFilterProps = {
  filter: SearchFilter;
};

const PetAttributesFilter: React.FC<PetAttributesFilterProps> = ({
  filter,
}) => {
  const [isFilterPaneOpen, setIsFilterPaneOpen] = useState(false);

  return (
    <div>
      <FilterPane
        isFilterPaneOpen={isFilterPaneOpen}
        setIsFilterPaneOpen={setIsFilterPaneOpen}
      />
      <div className="fixed top-16 left-3 md:left-5 flex z-10 shadow-lg">
        <button
          className={getFilterButtonClasses(isFilterSet(filter))}
          onClick={() => setIsFilterPaneOpen(true)}
        >
          <LuListFilter />
        </button>
      </div>
    </div>
  );
};

export default PetAttributesFilter;
